import React, { useState, useRef, useEffect } from 'react'
import { motion, AnimatePresence, useInView, useScroll, useTransform } from 'framer-motion'
import { LineChart, BarChart, Image as ImageIcon, Users, DollarSign, TrendingUp, ChevronRight } from 'lucide-react'
import { Card, CardContent } from "./ui/card.tsx"
import { Badge } from "./ui/badge.tsx"
import { Progress } from "./ui/progress.tsx"

const features = [
  {
    title: 'Real-Time Data Visualizations',
    description: 'Track your business performance with interactive charts and real-time metrics that matter.',
    benefit: 'Make informed decisions with real-time analytics and customizable dashboards.',
    icon: LineChart,
    color: 'from-blue-400 to-blue-600',
    image: '/placeholder.svg?height=600&width=800',
    metrics: null
  },
  {
    title: 'AI-Powered Ad Image Generation',
    description: 'Create stunning ad creatives instantly with our advanced AI generation tools.',
    benefit: 'Save time and resources while maintaining brand consistency across campaigns.',
    icon: ImageIcon,
    color: 'from-purple-400 to-purple-600',
    image: '/placeholder.svg?height=600&width=800',
    metrics: null
  },
  {
    title: 'Inventory Tracking and Management',
    description: 'Stay on top of your inventory with real-time tracking and automated alerts.',
    benefit: 'Optimize stock levels and prevent stockouts with predictive analytics.',
    icon: BarChart,
    color: 'from-green-400 to-green-600',
    image: '/placeholder.svg?height=600&width=800',
    metrics: null
  },
  {
    title: 'Sales and Revenue Tracking',
    description: 'Track sales performance, revenue growth, and customer purchase behaviors in real-time.',
    benefit: 'Focus on your best-performing products and tailor promotions accordingly.',
    icon: DollarSign,
    color: 'from-yellow-400 to-yellow-600',
    image: '/placeholder.svg?height=600&width=800',
    metrics: {
      revenue: { value: '$45,231.89', change: '+20.1% from last month' },
      subscriptions: { value: '+2350', change: '+180.1% from last month' },
      sales: { value: '+12,234', change: '+19% from last month' },
      active: { value: '+573', change: '+201 since last hour' }
    }
  },
  {
    title: 'Customer Insights and Management',
    description: 'Manage customer profiles and generate insights into customer segments.',
    benefit: 'Tailor marketing campaigns to specific customer segments, improving retention and lifetime value.',
    icon: Users,
    color: 'from-red-400 to-red-600',
    image: '/placeholder.svg?height=600&width=800',
    metrics: {
      campaign: {
        name: 'GenZ audience targeting',
        budget: '$1500',
        location: 'California',
        dates: '11/10/2024 - 11/18/2024',
        goals: ['Increase Sales', 'Brand Awareness', 'Website Traffic']
      }
    }
  },
  {
    title: 'Social Media Trend Analytics',
    description: 'Stay on top of social media trends and engagement metrics in real-time.',
    benefit: 'Make data-driven decisions by tracking social media performance, trending topics, and customer engagement.',
    icon: TrendingUp,
    color: 'from-indigo-400 to-indigo-600',
    image: '/placeholder.svg?height=600&width=800',
    metrics: {
      growth: '24.8%',
      engagement: '1.2M',
      sentiment: '78%',
      topics: ['Marketing', 'Business', 'Entrepreneur', 'Success', 'Innovation']
    }
  }
]

export default function AdvancedFeaturesShowcase() {
  const [activeFeature, setActiveFeature] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false)
  const containerRef = useRef(null)
  const isInView = useInView(containerRef, { once: true, amount: 0.2 })
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end start"]
  });

  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0.8, 1, 1, 0.8]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const featureVariants = {
    hidden: { 
      opacity: 0, 
      y: 20,
      scale: 0.95
    },
    visible: { 
      opacity: 1, 
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 15
      }
    },
    hover: {
      scale: 1.02,
      y: -5,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10
      }
    }
  };

  const iconVariants = {
    initial: { scale: 0, rotate: -180 },
    animate: { 
      scale: 1, 
      rotate: 0,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isExpanded) {
        setActiveFeature((prev) => (prev + 1) % features.length)
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [isExpanded])

  const MetricsDisplay = ({ metrics, type }) => {
    if (!metrics) return null

    switch (type) {
      case 'Sales and Revenue Tracking':
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 md:gap-4 mt-4">
            {Object.entries(metrics).map(([key, data]) => (
              <Card key={key} className="bg-gray-50">
                <CardContent className="p-3 md:p-4">
                  <h4 className="text-xs md:text-sm font-medium text-gray-500 capitalize">{key}</h4>
                  <p className="text-xl md:text-2xl font-bold">{data.value}</p>
                  <p className="text-xs md:text-sm text-gray-600">{data.change}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        )

      case 'Customer Insights and Management':
        return (
          <div className="mt-4 space-y-3 md:space-y-4">
            <Card className="bg-gray-50">
              <CardContent className="p-3 md:p-4">
                <h4 className="text-sm md:text-base font-medium">{metrics.campaign.name}</h4>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 mt-2 text-xs md:text-sm">
                  <div>Budget: {metrics.campaign.budget}</div>
                  <div>Location: {metrics.campaign.location}</div>
                </div>
                <div className="mt-2 flex flex-wrap gap-2">
                  {metrics.campaign.goals.map((goal) => (
                    <Badge key={goal} variant="secondary" className="text-xs md:text-sm">
                      {goal}
                    </Badge>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        )

      case 'Social Media Trend Analytics':
        return (
          <div className="mt-4 space-y-3 md:space-y-4">
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 md:gap-4">
              <div>
                <h4 className="text-sm font-medium text-gray-500">Growth Rate</h4>
                <p className="text-2xl font-bold">{metrics.growth}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500">Engagement</h4>
                <p className="text-2xl font-bold">{metrics.engagement}</p>
              </div>
              <div>
                <h4 className="text-sm font-medium text-gray-500">Sentiment</h4>
                <div className="flex items-center gap-2">
                  <Progress 
                    value={parseInt(metrics.sentiment.replace('%', ''))} 
                    className="h-2 w-full bg-gray-200" 
                    indicatorClassName="bg-blue-600"
                  />
                  <span className="text-sm font-medium">{metrics.sentiment}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              {metrics.topics.map((topic) => (
                <Badge key={topic} variant="outline">
                  {topic}
                </Badge>
              ))}
            </div>
          </div>
        )

      default:
        return null
    }
  }

  return (
    <motion.section 
      ref={containerRef} 
      className="py-12 md:py-24 px-4 bg-gradient-to-br from-gray-50 to-white overflow-hidden"
      style={{ opacity, scale }}
    >
      <div className="max-w-7xl mx-auto">
        <motion.h2 
          className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8 md:mb-16"
          initial={{ opacity: 0, y: -20 }}
          animate={isInView ? { opacity: 1, y: 0 } : {}}
          transition={{ 
            duration: 0.8,
            type: "spring",
            bounce: 0.4
          }}
        >
          <motion.span
            initial={{ backgroundSize: "0% 100%" }}
            animate={{ backgroundSize: "100% 100%" }}
            transition={{ duration: 1, delay: 0.5 }}
            className="bg-gradient-to-r from-blue-600 to-purple-600 bg-no-repeat bg-bottom"
            style={{ 
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundSize: "0% 3px"
            }}
          >
            Powerful Features
          </motion.span>{" "}
          for Your Business
        </motion.h2>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          className="relative"
        >
          <motion.div
            className={`grid gap-4 md:gap-8 ${isExpanded ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'}`}
            layout
            transition={{ duration: 0.5 }}
          >
            <AnimatePresence mode="wait">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  variants={featureVariants}
                  whileHover="hover"
                  className={`bg-white rounded-xl shadow-lg overflow-hidden ${
                    !isExpanded && activeFeature !== index ? 'hidden md:hidden' : ''
                  }`}
                  layout
                >
                  <div className="p-4 md:p-6">
                    <motion.div 
                      className={`w-10 h-10 md:w-12 md:h-12 rounded-full mb-4 bg-gradient-to-br ${feature.color} flex items-center justify-center`}
                      variants={iconVariants}
                      initial="initial"
                      animate="animate"
                    >
                      <feature.icon className="w-5 h-5 md:w-6 md:h-6 text-white" />
                    </motion.div>
                    
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: index * 0.1 + 0.3 }}
                    >
                      <h3 className="text-lg md:text-xl font-semibold mb-2">{feature.title}</h3>
                      <p className="text-sm md:text-base text-gray-600 mb-4">{feature.description}</p>
                      <p className="text-sm md:text-base text-blue-600 font-medium">{feature.benefit}</p>
                    </motion.div>

                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 + 0.5 }}
                    >
                      <MetricsDisplay metrics={feature.metrics} type={feature.title} />
                    </motion.div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>

          <AnimatePresence>
            {!isExpanded && (
              <motion.button
                onClick={() => setIsExpanded(true)}
                className="mt-8 mx-auto block px-6 py-3 text-sm md:text-base bg-black text-white rounded-full font-medium flex items-center hover:bg-gray-800 transition-colors duration-300"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Explore All Features <ChevronRight className="ml-2 w-4 h-4 md:w-5 md:h-5" />
              </motion.button>
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </motion.section>
  )
}