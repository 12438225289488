import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import DemoRequestPage from './DemoRequestPage';
import ThankYouPage from './ThankYouPage';
import WaitlistPage from './WaitlistPage';
import ThankYouWaitlistPage from './ThankYouWaitlistPage';
import ContactPage from './ContactPage';
import ThankYouContactPage from './ThankYouContactPage';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import './globals.css'
import x from './X.js'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/demo-request" element={<DemoRequestPage />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/waitlist" element={<WaitlistPage />} />
        <Route path="/thank-you-waitlist" element={<ThankYouWaitlistPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/thank-you-contact" element={<ThankYouContactPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
