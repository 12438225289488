import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function ThankYouWaitlistPage() {
  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg text-center"
      >
        <h2 className="text-2xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">
          You're on the List!
        </h2>
        <p className="text-gray-600 mb-8">
          Thank you for joining our waitlist! We'll keep you updated on our launch and you'll be among the first to experience flonova.
        </p>
        <Link
          to="/flonova"
          className="inline-block px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white font-semibold rounded-md transition-colors"
        >
          Back to Home
        </Link>
      </motion.div>
    </div>
  );
} 